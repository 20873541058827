import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import contact from "../images/contact.svg"

function ContactPage() {
  const lang = "de";
  return (
    <Layout lang={lang}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <section className="bg-blue-600">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2">
            <img className="w-2/3 mx-auto md:mx-0 -mt-16 mb-8" src={contact} alt=""/>
          </div>
          <div className="md:w-1/2">
            <h1 className="text-white mb-0 md:mb-4">Lassen Sie uns zusammenarbeiten.</h1>
            <div className="mb-8 text-white text-xl">Hinterlassen Sie eine Nachricht und <br/> wir werden über Ihr Projekt sprechen.</div>
            <div className="card bg-blue-100 border-blue-300">
              <form name="contactpage" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contactpage" />
                <div className="flex flex-col mb-4">
                  <label className="label text-blue-500 text-xs mb-2" htmlFor="Email">Email</label>
                  <input className="border border-blue-300 shadow py-3 px-3 rounded" placeholder="Email" type="text" name="Email"/>
                </div>
                <div className="flex flex-col mb-4">
                  <label className="label text-blue-500 text-xs mb-2" htmlFor="Message">Nachricht</label>
                  <textarea className="border border-blue-300 shadow py-3 px-3 rounded" placeholder="Message" rows="4" name="Message"/>
                </div>
                <button className="bg-blue-700 px-3 py-3 w-full rounded shadow-md text-white" type="send">Senden</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
